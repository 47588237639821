<template>
    <div class="dataScreenMain">
        <header class="header flex-align-between">
            <div class="itemLeft"></div>

            <div class="title" v-if="$route.query.type == 'school'">
                {{ $route.query.name + "应用统计" }}
            </div>

            <div class="title" v-else-if="$route.query.type == 'class'">
                <span>{{ $route.query.schoolName }}{{ $route.query.classname || classname }}</span>
            </div>
            <div class="title" v-else>首师优字房山区应用统计</div>
            <div class="itemRight"></div>

            <!-- <div class="goback flex-align-between">
                <img src="../../assets/datascreen/return.png" @click="goback" />
            </div> -->
        </header>
        <router-view @childClick="childClick"></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {
            titleGroup: {
                areaData: "首师优字房山区应用统计",
                schoolData: "首师优字海淀一小应用统计",
                classData: " 首师优字三年级2班数据大屏",
            },
            classname: "",
        };
    },
    //方法
    methods: {
        getPath() {
            console.log(1111);
        },
        goback() {
            this.$router.go(-1);
        },
        childClick(e) {
            this.classname = e;
            // console.log('this.classname接收子路由传来的值', this.classname)
            // console.log("接收子路由传来的值= ", e);
        },
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() { },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() { },
};
</script>
<style lang='less' scoped>
@import "./less/dataScreenMain.less";
</style>